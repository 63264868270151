
import { Vue, Options } from "vue-class-component";
import { CargoController } from "@/app/ui/controllers/CargoController";
import convertDecimalWithComma from "@/app/infrastructures/misc/common-library/ConvertDecimalWithComma";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { NGenController } from "@/app/ui/controllers/NGenController";
import { FlightDetail } from "@/domain/entities/NGen";
import numberOnly from "@/app/infrastructures/misc/common-library/NumberOnly";
import { OptionsClass } from "@/domain/entities/MainApp";
import { ReadyToCargoController } from "@/app/ui/controllers/ReadyToCargoController";
import { DimensionData } from "@/domain/entities/Cargo";
import PopupDetailKoli from "../components/popup-detail-koli.vue";
import { flags } from "@/feature-flags";
import formatInputDecimalOnly2Digits from "@/app/infrastructures/misc/common-library/formatInputDecimalOnly2Digits";
import { checkValueOnBlurInput } from "@/app/infrastructures/misc/Utils";

@Options({
  components: { PopupDetailKoli }
})
export default class CargoDetailDeliverySection extends Vue {
  mounted() {
    this.getCargoProductList();
    if (this.flowBooking === "rtc-process" && this.cargoInputForm.id == 0) {
      CargoController.calculationVolumeWeight(this.totalPieces);
    }
  }

  showInfoDividerCargo = true;

  get isErrorInputStepThree() {
    return CargoController.isErrorInputStepThree;
  }

  get flowBooking() {
    return ReadyToCargoController.flowBooking;
  }

  formatNumberOnly(value: string) {
    return numberOnly(value);
  }

  formatFloat(value: string) {
    return formatInputDecimalOnly2Digits(value);
  }

  formatValue(value: string, type: string) {
    if (checkValueOnBlurInput(value) === "withoutComma") {
      const newValue = Number(value).toFixed(2).replace(".", ",");;
      if (type === "grossWeight") {
        this.formBooking.actualTotalCargoGrossWeight = newValue;
      } else {
        this.formBooking.actualTotalCargoVolumeWeight = newValue;
      }
    } else if (checkValueOnBlurInput(value) === "oneValueAfterComma") {
      if (type === "grossWeight") {
        this.formBooking.actualTotalCargoGrossWeight = value + "0";
      } else {
        this.formBooking.actualTotalCargoVolumeWeight = value + "0";
      }
    } else if (checkValueOnBlurInput(value) === "minimumValue") {
      const newValueMinimum = value.split(",")[0];
      if (type === "grossWeight") {
        this.formBooking.actualTotalCargoGrossWeight = newValueMinimum + ",10";
      } else {
        this.formBooking.actualTotalCargoVolumeWeight = newValueMinimum + ",10";
      }
    }
  }

  convertDecimal(value: string) {
    return convertDecimalWithComma(value, 2);
  }

  get dataProfile() {
    return AccountController.accountData;
  }

  get detailDistrict() {
    return LocationController.districtDetail;
  }

  get cargoInputForm() {
    return CargoController.cargoInputForm;
  }

  get partnerName() {
    return this.dataProfile.account_type_detail.company_name;
  }

  get typeUser() {
    return this.dataProfile.normalizedTypeUser;
  }

  get formBooking() {
    return CargoController.form;
  }

  get isProcessFromRTC() {
    return ReadyToCargoController.flowBooking === "rtc-process"
  }

  get sttResultFinal() {
    CargoController.setSTTResultFinal(CargoController.groupedSTTResultFinal);
    return CargoController.groupedSTTResultFinal
  }

  get commodityOptions(): OptionsClass[] {
    return CargoController.commodityOptions
  }

  get commodityItem() {
    return CargoController.commodityItem;
  }

  onChangeCargoNumber(value: string) {
    CargoController.setUpdateFromBookingCargoNumber(value);
  }

  onChangeVehicleNumber(value: string) {
    CargoController.setOnChangeVehicleNumber(value);
  }

  onChangeNog(value: string) {
    CargoController.setOnChangeNog(value);
  }

  onChangeDimension(value: DimensionData) {
    CargoController.setOnChangeDimension(value);
  }

  onChangeTotalCargoVolumeWeight(value: number) {
    CargoController.setOnChangeTotalCargoVolumeWeight(value);
  }

  onChangeTotalCargoGrossWeight(value: number) {
    CargoController.setOnChangeTotalActualGrossWeight(value);
  }

  onChangeTotalColi(value: number) {
    CargoController.setOnChangeTotalColi(value);
  }

  onSelectCommodity(val: any) {
    if (val.value === this.commodityItem.value) {
      CargoController.setCommodityItem({
        value: "",
        name: ""
      })
      this.formBooking.cargoCommodityName = "";
      this.formBooking.cargoCommodityCode = "";
      return;
    }
    CargoController.setCommodityItem(val)
    this.formBooking.cargoCommodityName = val.name;
    this.formBooking.cargoCommodityCode = val.value;
    const comodity = new OptionsClass({
      name: val.name,
      value: val.value
    });
    this.setCargoComodity(comodity);
  }

  setCargoComodity(value: OptionsClass) {
    CargoController.setCargoComodity(value);
  }

  // cargo product
  cargoProduct = "";

  getCargoProductList() {
    CargoController.getCargoProductList({
      status: "active"
    });
  }

  isOpenSelectCargoProduct = false;

  get listProductCargo() {
    const defaultChoices: any = [
      {
        name: this.$t("Pilih Produk"),
        value: "",
        status: ""
      }
    ];
    const cargos = CargoController.cargoProductList.data.map((e: any) => ({
      name: e.cargoProductTypeCode,
      value: e.cargoProductTypeCode,
      status: e.cargoProductTypeStatus
    }));

    return defaultChoices.concat(cargos);
  }

  calculationVolumeWeight() {
    CargoController.calculationVolumeWeight(this.totalPieces);
  }

  get totalPieces() {
    return CargoController.totalSttPieces;
  }

  onOpenSelectCargoProduct() {
    this.isOpenSelectCargoProduct = true;
  }

  onCloseSelectCargoProduct() {
    this.isOpenSelectCargoProduct = false;
  }

  onSelectCargoProduct(_: string, value: string) {
    this.onCloseSelectCargoProduct();
    CargoController.setOnSelectCargoProduct(value);
  }

  get isCargoTrain() {
    return this.formBooking.cargoType === "train";
  }

  get isCargoShip() {
    return this.formBooking.cargoType === "ship";
  }

  get isCargoTruck() {
    return this.formBooking.cargoType === "truck";
  }

  get isCargoPlaneManual() {
    return (
      this.formBooking.cargoType === "plane" &&
      this.formBooking.cargoTypeStatus !== "auto"
    );
  }

  get isCargoPlaneAuto() {
    return (
      this.formBooking.cargoType === "plane" &&
      this.formBooking.cargoTypeStatus === "auto"
    );
  }

  get errorAwb() {
    return NGenController.errorAwb;
  }

  get flightsData() {
    return CargoController.form.flightsData;
  }

  get vehicleNumber() {
    if (this.formBooking.vehicleNumber && !this.isCargoPlaneAuto) {
      return this.formBooking.vehicleNumber;
    }
    return this.isCargoPlaneManual
      ? this.formBooking.vehicleNumber
      : this.flightsData
          .map((item: FlightDetail) => {
            return item.FlightNo;
          })
          .join(", ");
  }
  get isHaveBeenSubmitDetailPieces() {
    return CargoController.isHaveBeenSubmitDetailPieces;
  }

  showPopupDimensionPieces(val: boolean) {
    CargoController.setShowPopupDimensionPieces(val);
    if (!this.isHaveBeenSubmitDetailPieces) {
      CargoController.formDetailPieces.totalPieces = this.formBooking.actualTotalSTTPieces;
    }
  }

  get isShowPopupDimensionPieces() {
    return CargoController.showPopupDimensionPieces;
  }

  get formDetailPieces() {
    return CargoController.formDetailPieces;
  }

  get isErrorDiffPiecesActualThanDimensionPieces() {
    if (this.showButtonDimension) {
      return false;
    }
    return CargoController.isErrorDiffPiecesActualThanDimensionPieces;
  }
  get showButtonDimension() {
    return flags.feature_hide_dimension_koli.isEnabled();
  }
  get shcValue() {
    return this.formBooking.shcCode && this.formBooking.shcName ? `${this.formBooking.shcCode} - ${this.formBooking.shcName}` : "-";
  }
}
