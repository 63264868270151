
import { mixins, Options } from "vue-class-component";
import OutGoingMixins from "@/app/ui/views/out-going-shipment/out-going-mixin";
import CargoTypeSection from "@/app/ui/views/cargo/booking-cargo/third-step/cargo-type-section.vue";
import CargoDetailDeliverySection from "@/app/ui/views/cargo/booking-cargo/third-step/cargo-detail-delivery-section.vue";
import CargoTableSttSection from "@/app/ui/views/cargo/booking-cargo/third-step/cargo-table-stt-section.vue";
import CargoFlightManualSection from "@/app/ui/views/cargo/booking-cargo/third-step/cargo-flight-manual-section.vue";
import { CargoController } from "@/app/ui/controllers/CargoController";
import { ReadyToCargoController } from "@/app/ui/controllers/ReadyToCargoController";
import { gtmCargoData } from "@/app/ui/views/cargo/modules/gtm";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";

@Options({
  components: {
    CargoFlightManualSection,
    CargoTableSttSection,
    CargoDetailDeliverySection,
    CargoTypeSection
  }
})
export default class ThirdStep extends mixins(OutGoingMixins) {
  created() {
    if (this.formBooking.cargoType === "plane") {
      // GTM
      if (!this.isCargoPlaneManual) {
        const gtmDetailView = gtmCargoData({
          type: this.formBooking.cargoType,
          isAuto: !this.isCargoPlaneManual
        }).detailView;
        dataLayer(gtmDetailView.eventName, gtmDetailView.property, []);
      } else {
        const gtmDetailViewAwb = gtmCargoData({
          type: this.formBooking.cargoType,
          isAuto: !this.isCargoPlaneManual
        }).detailViewAwb;
        dataLayer(gtmDetailViewAwb.eventName, gtmDetailViewAwb.property, []);
      }
    }
  }
  mounted() {
    if (this.flowBooking == "rtc-process") {
      this.formBooking.cargoType = ReadyToCargoController.readyToCargoDetail.cargoType;
    } else {
      this.formBooking.cargoType = CargoController.cargoType?.type;
      this.formBooking.cargoTypeStatus = CargoController.cargoType?.status;
    }

    if (CargoController.isFillForm) {
      CargoController.setFillForm(CargoController.isFillForm);
      return;
    }
    CargoController.setFillForm(!this.isCargoPlaneManual);
  }

  mappingReadyToCargo() {
    CargoController.mappingReadyToCargo();
  }

  get flowBooking() {
    return ReadyToCargoController.flowBooking;
  }

  get isFillForm() {
    return CargoController.isFillForm;
  }
  get formBooking() {
    return CargoController.form;
  }
  get isCargoPlaneManual() {
    return (
      this.formBooking.cargoType === "plane" &&
      this.formBooking.cargoTypeStatus !== "auto"
    );
  }
}
